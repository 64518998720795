import { createAxiosResponse } from 'api/utils';
import { AxiosResponse } from 'axios';
import { List } from 'lodash';
import {
  AppliedPaymentInfo,
  Check,
  FreedomPayKeyRequest,
  NCRSecurePaymentMakeTransactionRequest,
  NCRSecurePaymentResponse,
  NCRSecurePaymentStartSessionRequest,
  SiteConfiguration,
  ThemeConfigSettings,
} from 'types/DTOs';
import { GetGiftCardBalanceResponse } from 'types/DTOs/GiftCard/GetGiftCardBalanceResponse';
import { GiftCardPayRequest } from 'types/DTOs/GiftCard/GiftCardPayRequest';
import { GiftCardPayResponse } from 'types/DTOs/GiftCard/GiftCardPayResponse';
import { MemberProfileResponse } from 'types/DTOs/MemberProfileResponse';
import { FreedomPayKeyResponse } from 'types/DTOs/NCRSecurePayment/FreedomPayKeyResponse';
import { PaymentResponse } from 'types/DTOs/Payment';
import {
  creditCardPaymentRequest,
  PayNoBalanceRequest,
} from 'types/DTOs/PaymentRequests';
import { Response } from 'types/DTOs/Response';
import { PaymentMethod } from 'types/DTOs/SiteConfiguration';
import { PERCENT } from 'types/DTOs/TipBase';
import { mockCheck } from './mockValues';

//#region POST
const logRefCode = (refCode: string, checkCode: string) =>
  new Promise<AxiosResponse>((resolve) =>
    setTimeout(() => resolve(createAxiosResponse('')), 100)
  );

const setPreDiscountItemsTotal = (
  code: string,
  preDiscountItemsTotal: number
) =>
  new Promise<AxiosResponse<Response>>((resolve) =>
    resolve(
      createAxiosResponse<Response>({
        Status: 0,
        Message: '',
      })
    )
  );
//#endregion POST

//#region GET Information
const getCheck = async (
  checkCode: string,
  firstLoad = false
): Promise<AxiosResponse<Check>> =>
  new Promise<AxiosResponse<Check>>((resolve) =>
    setTimeout(() => resolve(createAxiosResponse<Check>(mockCheck)), 500)
  );

const getSiteConfig = (checkCode: string) =>
  new Promise<AxiosResponse<SiteConfiguration>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<SiteConfiguration>({
            AlohaLoyalty: false,
            CurrencySign: '$',
            CustomerVoice: true,
            OrderAnother: false,
            PageServer: true,
            PayPalEnabled: true,
            PayPalFindRedirect: false,
            PlaceType: 0,
            SignatureThreshold: 10,
            StoreName: 'DEMO',
            ExternalStoreName: 'Demo Grille',
            StoredValue: false,
            TipOne: 18,
            TipTwo: 20,
            TipThree: 22,
            IsUseTipConfiguration: true,
            OpenTableEnabled: false,
            MaxPaymentThreshold: 1000,
            EDCCvvEnabled: false,
            EDCAVSEnabled: false,
            IsUseNCRSecurePaymentForCC: false,
            TipBase: PERCENT,
            TipCalculationBase: 'subtotal',
            CompanyId: 11111,
            CPStoreNumber: 111,
            StoredValueEnabled: false,
            CompanyCode: 'DEMOOO',
            AsvPrefixEnabled: false,
            LoyaltyCompanyId: 123,
            PaymentMethod: PaymentMethod.EDC,
            FPApiEndpoint: 'Demo Endpoint',
            FPEnable3DS: false,
            FPEnableGooglePay: false,
            FPEnableApplePay: false,
            FPGooglePayMerchantId: 'google_pay_merchant_id',
            FPGatewayMerchantId: 'gateway_merchant_id',
            FPGooglePayEnv: 'TEST',
            AppInsightsInstrumentation: 'AppInsightsInstrumentation',
            AppInsightsRoleName: 'AppInsightsRoleName',
            AppInsightsRoleInstance: 'AppInsightsRoleInstance',
          })
        ),
      500
    )
  );

const getThemeConfig = (subdomain: string) =>
  new Promise<AxiosResponse<ThemeConfigSettings>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<ThemeConfigSettings>({
            aeRequestCompany: 'aerc',
            aeRequestUser: 'aeru',
            aeRequestPassword: 'aerp',
            aeSiteId: 11,
            redirectUrl: 'redirectUrl',
            cvQuestion: 'How was your experience?',
            cvRatingLow: 'Oh no!',
            cvRatingMedium: 'Not bad',
            cvRatingHigh: 'Woohoo',
            sidebarAccountText: 'sidebardaccounttext',
            sidebarPaymentText: 'sidebarpaymenttext',
            sidebarLogoutText: 'sidebardlogouttext',
            sidebarLoyaltyText: 'sidebardloyaltytext',
            sidebarHomeText: 'sidebarhometext',
            sidebarCheckText: 'sidebarchecktext',
            sidebarEmailReceiptText: 'sidebaremailreceipttext',
            thankYouText: 'Thank you for your business',
            welcomeText: 'Simple & safe payment from your phone',
            gaClientUaId: 'ga',
            buttonBackgroundColor: 29413,
            buttonFontColor: 16777215,
            dividerColor: 3458905,
          })
        ),
      200
    )
  );

const getAppliedPaymentInfo = (checkCode: string) =>
  new Promise<AxiosResponse<AppliedPaymentInfo>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<AppliedPaymentInfo>({
            AmountPaid: 0,
            Balance: 20.66,
            Closed: false,
            Gratuity: 0,
            Subtotal: 19.36,
            Tax: 1.3,
            Status: 0
          })
        ),
      100
    )
  );
//#endregion GET Information

//#region Loyalty Calls
const getLoyaltyByPhoneNumber = (siteId: number, phoneNumber: string) =>
  new Promise<AxiosResponse<MemberProfileResponse>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<MemberProfileResponse>({
            Message: 'Test member',
            MemberProfile: {
              CardNumber: '',
              MaskedCardNumber: '',
              EmailAddress: '',
              AnniversaryDate: '',
              PhoneNumber: '123-456-7890',
              OtherPhoneNumber: '',
              Address1: '',
              Address2: '',
              City: '',
              State: '',
              Country: '',
            },
          })
        ),
      100
    )
  );

const assignLoyalty = (code: string, number: string) =>
  new Promise<AxiosResponse<Response>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<Response>({
            Status: 200,
            Message: 'Test ApplyLoyalty',
          })
        ),
      100
    )
  );

const getLoyaltyStatus = (code: string) =>
  new Promise<AxiosResponse<Response>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<Response>({
            Status: 0,
            Message: 'Loyalty unassigned',
          })
        ),
      100
    )
  );

const setLoyaltySavingsAndItemsTotal = (
  code: string,
  loyaltySavingsOnItems: number,
  loyaltySavingsOnOtherCharges: number
) => {};

const resetLoyaltySavingsAndItemsTotal = (code: string) => {};
//#endregion Loyalty Calls

//#region Gift Card
const getEpinPrefixList = (companyCode: string) =>
  new Promise<AxiosResponse<List<string>>>((resolve) =>
    setTimeout(() => resolve(createAxiosResponse<List<string>>([])), 100)
  );

const getAmountPaid = (paymentId: any) =>
  new Promise<AxiosResponse<AppliedPaymentInfo>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<AppliedPaymentInfo>({
            AmountPaid: 0,
            Balance: 20.66,
            Closed: false,
            Gratuity: 0,
            Subtotal: 19.36,
            Tax: 1.3,
            Status:0
          })
        ),
      100
    )
  );

const getGiftCardBalance = (
  companyId: number,
  siteId: number,
  cardNumber: string,
  pin: string
) =>
  new Promise<AxiosResponse<GetGiftCardBalanceResponse>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<GetGiftCardBalanceResponse>({
            Balance: 100,
            Message: '',
            Status: 0,
          })
        ),
      100
    )
  );

const giftCardPay = (request: GiftCardPayRequest) =>
  new Promise<AxiosResponse<GiftCardPayResponse>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<GiftCardPayResponse>({
            Message: 'Success',
            PaymentId: 123123,
            Status: 0,
            AmountPaid: 5,
            SplitRemaining: 0,
            TipRemaining: 0,
          })
        ),
      100
    )
  );
//#endregion Gift Card

//#region Electornic Draft Captures (EDC)
const payNoBalance = (request: PayNoBalanceRequest) =>
  new Promise<AxiosResponse<PaymentResponse>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<PaymentResponse>({
            Status: 0,
            Message: 'Payment successful. Thank you.',
            PaymentId: 103718,
          })
        ),
      100
    )
  );

const newCreditCardPayment = (request: creditCardPaymentRequest) =>
  new Promise<AxiosResponse<PaymentResponse>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<PaymentResponse>({
            Status: 0,
            Message: 'Payment successful. Thank you.',
            PaymentId: 103718,
          })
        ),
      100
    )
  );
//#endregion Electornic Draft Captures (EDC)

//#region NCR Secure Payment (CP and FP)
const createNcrSecurePaymentSession = (
  request: NCRSecurePaymentStartSessionRequest
) =>
  new Promise<AxiosResponse<NCRSecurePaymentResponse>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<NCRSecurePaymentResponse>({
            // TODO: How would we mock this? Do we have to create our own fake CP page?
            RequestUrl: 'https://fr.wikipedia.org/wiki/Main_Page',
            PaymentStateId: '12345',
            MessageDetails: '',
            Status: 1,
            FreedomPayIFrame: '',
            Message: '',
          })
        ),
      500
    )
  );

const completeNCRSecurePaymentsTransaction = (
  request: NCRSecurePaymentMakeTransactionRequest
) =>
  new Promise<AxiosResponse<NCRSecurePaymentResponse>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<NCRSecurePaymentResponse>({
            RequestUrl: '',
            PaymentStateId: '12345',
            PaymentId: 123,
            MessageDetails: '',
            Status: 1,
            Message: '',
          })
        ),
      1000
    )
  );

/**
     * 
       paymentKeys: string[];
  paymentType: string;
}
     */
const sendPaymentKey = (request: FreedomPayKeyRequest) =>
  new Promise<AxiosResponse<FreedomPayKeyResponse>>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse<FreedomPayKeyResponse>({
            paymentKeys: ['123456-78945612'],
            paymentType: 'googlepay',
          })
        ),
      1000
    )
  );
//#endregion NCR Secure Payment (CP and FP)

//#region Email Receipt
const sendReceiptOnDemand = (
  checkCode: string,
  paymentId: number,
  emailAddress: string,
  subdomain: string,
  UTCOffset: number
) =>
  new Promise<AxiosResponse>((resolve) =>
    setTimeout(
      () =>
        resolve(
          createAxiosResponse({
            Status: 0,
            Message: "You've got mail! Your receipt has been emailed to you.",
          })
        ),
      100
    )
  );
//#endregion Email Receipt

export const mockApi = {
  getCheck,
  getSiteConfig,
  getThemeConfig,
  logRefCode,
  getAppliedPaymentInfo,
  getEpinPrefixList,
  getGiftCardBalance,
  giftCardPay,
  payNoBalance,
  newCreditCardPayment,
  createNcrSecurePaymentSession,
  sendPaymentKey,
  completeNCRSecurePaymentsTransaction,
  sendReceiptOnDemand,
  getLoyaltyByPhoneNumber,
  assignLoyalty,
  getLoyaltyStatus,
  setPreDiscountItemsTotal,
  setLoyaltySavingsAndItemsTotal,
  resetLoyaltySavingsAndItemsTotal,
  getAmountPaid,
};
